const FACET_PRIORITY_MAP: MatchDetails.AllowedMap = {
	BEA: [
		'd4d44516-41dc-400b-b957-fd680af6ca2e', // Beauty
	],
	CAT: [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'02d6895a-1ac2-4a0d-bfd9-d1aa13135d43', // Cuisine
	],
	DJS: [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'aac0d551-48c0-4f1a-bc97-70adcdd3a88e', // Music Genres
	],
	FLO: [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'1cf196dc-41ea-4b60-878c-447afdda4bed', // Flower Arrangements
	],
	PLN: [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'6e4c7aff-f80b-42be-aaa1-3cd876ab3d87', // Planning
	],
	REC: [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'44ed7871-6e3c-4105-806a-4fd9efe72c4e', // Guest Capacity
		'489f32ad-41e2-4c92-ae66-acb2439c0464', // Settings
	],
	VID: [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'de3bdda4-ac3a-455f-82d3-2be1d218640b', // Wedding Activities
	],
	WPH: [
		'd152eee4-6a3f-4116-9226-8a977d274b85', // Starting Price Range
		'de3bdda4-ac3a-455f-82d3-2be1d218640b', // Wedding Activities
	],
};

export { FACET_PRIORITY_MAP };
